import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[ContractType]';

export const contractTypeActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  DEACTIVATE: `${ACTION_PREFIX} Deactivate`,
  DEACTIVATE_SUCCESS: `${ACTION_PREFIX} Deactivate Success`,
  DEACTIVATE_FAILED: `${ACTION_PREFIX} Deactivate Failed`,

  ACTIVATE: `${ACTION_PREFIX} Activate`,
  ACTIVATE_SUCCESS: `${ACTION_PREFIX} Activate Success`,
  ACTIVATE_FAILED: `${ACTION_PREFIX} Activate Failed`,
};

export class ContractTypeAction {
  public static load(): Action {
    return {
      type: contractTypeActionType.LOAD,
    };
  }

  public static loadSuccess(contractType): Action {
    return {
      type: contractTypeActionType.LOAD_SUCCESS,
      payload: contractType,
    };
  }

  public static loadFailed(err): Action {
    return {
      type: contractTypeActionType.LOAD_FAILED,
      payload: err,
    };
  }

  public static add(contractTypeData): Action {
    return {
      type: contractTypeActionType.ADD,
      payload: contractTypeData,
    };
  }

  public static addSuccess(contractTypeResponse): Action {
    return {
      type: contractTypeActionType.ADD_SUCCESS,
      payload: contractTypeResponse,
    };
  }

  public static addFailed(err): Action {
    return {
      type: contractTypeActionType.ADD_FAILED,
      payload: err,
    };
  }

  public static update(contractTypeData): Action {
    return {
      type: contractTypeActionType.UPDATE,
      payload: contractTypeData,
    };
  }

  public static updateSuccess(contractTypeResponse): Action {
    return {
      type: contractTypeActionType.UPDATE_SUCCESS,
      payload: contractTypeResponse,
    };
  }

  public static updateFailed(err): Action {
    return {
      type: contractTypeActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  public static fetch(id: any) {
    return {
      type: contractTypeActionType.FETCH,
      payload: id,
    };
  }

  public static fetchSuccess(response) {
    return {
      type: contractTypeActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  public static fetchFailed(id: string, err) {
    return {
      type: contractTypeActionType.FETCH_SUCCESS,
      payload: {
        id,
        err,
      },
    };
  }

  public static deactivate(data): Action {
    return {
      type: contractTypeActionType.DEACTIVATE,
      payload: data,
    };
  }

  public static deactivateSuccess(data): Action {
    return {
      type: contractTypeActionType.DEACTIVATE_SUCCESS,
      payload: data,
    };
  }

  public static deactivateFailed(data): Action {
    return {
      type: contractTypeActionType.DEACTIVATE_FAILED,
      payload: data,
    };
  }

  public static activate(data): Action {
    return {
      type: contractTypeActionType.ACTIVATE,
      payload: data,
    };
  }

  public static activateSuccess(data): Action {
    return {
      type: contractTypeActionType.ACTIVATE_SUCCESS,
      payload: data,
    };
  }

  public static activateFailed(data): Action {
    return {
      type: contractTypeActionType.ACTIVATE_FAILED,
      payload: data,
    };
  }
}
