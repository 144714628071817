import { Injectable } from '@angular/core';
import { undo } from '@app/reducers/shared/undo/undoAction';
import { compose, Store } from '@ngrx/store';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import u from 'updeep';

import { AppState } from '../../index';
import { mapEntity } from '../../shared/entity.helper';
import { ContractTypeAction } from './contract-type.action';
import { ContractTypeApi } from './contract-type.api';
import { ContractTypeModel, ContractTypeState } from './contract-type.model';

@Injectable()
export class ContractTypeService {
  public constructor(
    private store: Store<AppState>,
    private api: ContractTypeApi,
  ) {}

  public load() {
    this.store.dispatch(ContractTypeAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(ContractTypeAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(ContractTypeAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(contractTypeData): Observable<any> {
    this.store.dispatch(ContractTypeAction.add(contractTypeData));

    return this.api.add(contractTypeData).pipe(
      map((response) => {
        this.store.dispatch(ContractTypeAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(ContractTypeAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, contractTypeData) {
    this.store.dispatch(ContractTypeAction.update(contractTypeData));

    return this.api.update(id, contractTypeData).pipe(
      map((response) => {
        this.store.dispatch(ContractTypeAction.updateSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(ContractTypeAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public fetch(id) {
    this.store.dispatch(ContractTypeAction.fetch(id));

    return this.api.fetch(id).pipe(
      map((response) => {
        this.store.dispatch(ContractTypeAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(ContractTypeAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public save(contractTypeData) {
    if (contractTypeData.id) {
      return this.update(contractTypeData.id, contractTypeData);
    }

    contractTypeData = u.omit('id', contractTypeData);

    return this.add(contractTypeData);
  }

  public deactivate(id: string) {
    const deactiveAction = ContractTypeAction.deactivate(id);
    this.store.dispatch(deactiveAction);

    return this.api.deactivate(id).pipe(
      switchMap((response) => {
        this.store.dispatch(ContractTypeAction.deactivateSuccess(id));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(undo(deactiveAction));
        this.store.dispatch(ContractTypeAction.activateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public activate(id: string) {
    const activateAction = ContractTypeAction.activate(id);
    this.store.dispatch(activateAction);

    return this.api.activate(id).pipe(
      switchMap((response) => {
        this.store.dispatch(ContractTypeAction.activateSuccess(id));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(undo(activateAction));
        this.store.dispatch(ContractTypeAction.activateFailed(response));
        return observableThrowError(response);
      }),
    );
  }
}

export const getContractTypeState = (appState: AppState): ContractTypeState => appState.orm.contractTypes;

export const getContractTypeIds = compose((state) => state.items, getContractTypeState);
export const getContractTypeEntities = createSelector(getContractTypeState, (state) => state.itemsById);
export const getContractTypes = createSelector(getContractTypeState, (contractTypeState): ContractTypeModel[] =>
  Object.values(contractTypeState.itemsById),
);
export const getActiveContractTypes = createSelector(getContractTypes, (entities): ContractTypeModel[] =>
  entities.filter((contractType) => !contractType.deleted),
);

export const getContractType = (id: string) =>
  createSelector(getContractTypeEntities, (entities): ContractTypeModel => mapEntity(id, entities));
